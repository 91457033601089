export const PAYMENT_METHODS = {
  SCHEME: 'scheme',
  PAYPAL: 'paypal',
  KLARNA_ACCOUNT: 'klarna_account',
  RATEPAY: 'ratepay',
  ONLINEBANKING_PL: 'onlineBanking_PL',
  IDEAL: 'ideal',
  KLARNA: 'klarna',
  BILLIE: 'klarna_b2b',
  APPLEPAY: 'applepay',
  GOOGLEPAY: 'googlepay',
  MULTIBANCO: 'multibanco',
  TWINT: 'twint',
  MOBILEPAY: 'mobilepay'
} as const;

export const PAYMENT_METHODS_THAT_REQUIRE_RESOLVING_ACTIONS_IN_ON_SUBMIT = [
  PAYMENT_METHODS.GOOGLEPAY,
  PAYMENT_METHODS.APPLEPAY
];

export const B2C_KLARNA_METHODS: string[] = [
  PAYMENT_METHODS.KLARNA,
  PAYMENT_METHODS.KLARNA_ACCOUNT
];

export type PaymentMethods = ValueOf<typeof PAYMENT_METHODS>;

export const PDP_NAV_ANCHORS = {
  PRODUCT_DESCRIPTION: 'product-description',
  PRODUCT_DETAILS: 'product-details',
  TECHNICAL_DETAILS: 'technical-details',
  WARRANTY: 'warranty',
  RATING_REVIEWS: 'rating-reviews',
  FAQ: 'faq',
  ABOUT_BRAND: 'about-brand',
  SIMILAR_PRODUCTS: 'similar-products',
  RELATED_ACCESSORIES: 'product-accessories',
  PARENTS_FOR_ACCESSORY: 'parent-products-for-accessory',
  SIBLING_CATEGORIES: 'sibling-categories'
} as const;

export const PDP_NAV_ANCHOR_REFS = {
  NAV_ACTIVE_SECTION_ID: 'pdp-anchor-nav-active-section',
  NAVBAR_ELEMENT: 'pdp-anchor-nav-bar'
} as const;

import { useMakeOrder, useShipping } from '@vsf-enterprise/commercetools';
import { computed } from '@nuxtjs/composition-api';
import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import {
  useCartExtended,
  useCheckout,
  useCheckoutTagManager, useClientEmail,
  useIntegrations
} from '~/composables';
import { PaymentMethods } from '~/constants/paymentMethods';

export default function () {
  const { $tagManager } = useIntegrations();
  const { products } = useCheckoutTagManager();
  const { cart } = useCartExtended();
  const {
    order
  } = useMakeOrder();
  const { paymentSelected } = useCheckout();
  const { clientEmail } = useClientEmail();
  const { shipping: address } = useShipping();

  const filteredProducts = computed(
    () => products.value?.filter(product => !!product.sku) as ProductVariant[]
  );

  function callPayNowEvent(paymentMethod: PaymentMethods) {
    $tagManager.events.triggerPayNowTags({
      cart: cart.value || order.value,
      products: filteredProducts.value,
      paymentMethod,
      paymentType: paymentSelected?.value,
      purchaseUserInfo: {
        email: clientEmail.value,
        phone: address.value?.phone || ''
      }
    }
    );
  }

  return {
    callPayNowEvent
  };
}












import { computed, defineComponent } from '@nuxtjs/composition-api';
import { UseMyAccountPage } from '~/types/myaccount/UseMyAccountPage';

export default defineComponent({
  name: 'MyAccountDropdownItem',
  props: {
    url: {
      type: Object as () => ReturnType<UseMyAccountPage>,
      required: true
    }
  },
  emits: ['click'],
  setup(props) {
    const dataTestId = computed(
      () => props.url.key.value === 'billing-details' ? 'dropdownBillingDetails' : ''
    );

    return { dataTestId };
  }
});





















import { defineComponent, computed, defineAsyncComponent, onMounted } from '@nuxtjs/composition-api';
import { useUser } from '@vsf-enterprise/commercetools';
import { ref } from '@vue/composition-api';
import { onSSR } from '@vue-storefront/core';
import NoFooterLayout from './no-footer-layout.vue';
import { useCategoryExtended, useI18n, useIsPage } from '~/composables';
import AppFooterWrapper from '~/components/organisms/Footer/AppFooterWrapper/AppFooterWrapper.vue';

export default defineComponent({
  name: 'Default',
  components: {
    NoFooterLayout,
    Newsletter: defineAsyncComponent(() => import(/* webpackChunkName: "Newsletter" */
      '~/components/molecules/Newsletter/Newsletter.vue')),
    AppFooterWrapper
  },
  props: {
    fullWidth: Boolean
  },
  setup() {
    const { isAuthenticated } = useUser();
    const showNewsletter = computed(() => !isAuthenticated.value);
    const isMounted = ref(false);
    const { isOnProductPage } = useIsPage();
    const { languageAndCountry } = useI18n();
    const paddingForStickyAddToCart = 'pb-22 lg:pb-18';

    const {
      loadFirstLevelCategories,
      firstLevelCategoriesContainer
    } = useCategoryExtended();

    onSSR(async () => {
      await loadFirstLevelCategories(languageAndCountry.value);
    });

    onMounted(() => {
      isMounted.value = true;
    });

    return {
      isMounted,
      showNewsletter,
      isOnProductPage,
      paddingForStickyAddToCart,
      firstLevelCategoriesContainer
    };
  }
});

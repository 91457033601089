const { gql } = require('graphql-tag');
const DefaultCart = require('../fragments/defaultCart').fragment;
/*
 * The actual cart mutation is on the very bottom.
 */
module.exports = gql`
  ${DefaultCart}

  mutation createCart(
    $draft: MyCartDraft!,
    $locale: Locale!,
    $acceptLanguage: [Locale!],
    $currency: Currency!,
    $storeKey: KeyReferenceInput) {
    cart: createMyCart(draft: $draft, storeKey: $storeKey) {
      ...DefaultCart
    }
  }
`;

import { useIntegrations } from '~/composables';
import { catchApiErrors } from '~/utils/ct/composables/helpers/apiHelpers';
import { ApiParams } from '~/utils/ct';

export function useUserOrderApi() {
  const { $ct } = useIntegrations();

  const search = async ({ customQuery, ...searchParams }: ApiParams) => {
    const response = await $ct.api.getOrders(searchParams, customQuery);

    catchApiErrors(response);

    return response?.data?.me?.orders;
  };

  return {
    search
  };
}

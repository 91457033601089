/*
 * This is a mapping between commercetools customer field names and customer update actions
 * for example a function that returns a 'setVatId' action to update 'vatId' field should be under 'vatId' key here
 */
module.exports = (updatedFields) => Object.keys(updatedFields).map(key => propertiesMap[key](updatedFields[key]));

const propertiesMap = {
  email: (email) => ({
    changeEmail: { email }
  }),
  vatId: (vatId) => ({
    setVatId: { vatId }
  }),
  firstName: (firstName) => ({
    setFirstName: { firstName }
  }),
  lastName: (lastName) => ({
    setLastName: { lastName }
  })
};

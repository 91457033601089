const { CartFragment } = require('@vsf-enterprise/commercetools-api');

module.exports = `
  ${CartFragment}

  query getMyCart($cartId: String!, $locale: Locale!, $acceptLanguage: [Locale!], $currency: Currency!, $storeKey: KeyReferenceInput!) {
    inStore(key: $storeKey) {
      me {
        cart(id: $cartId) {
          ...DefaultCart
        }
      }
    }
  }
`;

const { updateCart } = require('./updateCart');

/**
 * Endpoint for updating quantity of given product from the cart. By default, it uses
 * the {@link updateCartDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param cart - Cart information
 * @param product - Product information
 * @param customQuery - Custom queries included in the request
 * @returns User cart information
 */
module.exports.updateCartQuantity = async (context, cart, product, customQuery) => {
  const createChangeLineItemQuantityAction = (product) => ({
    changeLineItemQuantity: {
      lineItemId: product.id,
      quantity: product.quantity
    }
  });
  return await updateCart(context, {
    ...cart,
    actions: [createChangeLineItemQuantityAction(product)]
  }, customQuery);
};

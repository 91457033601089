
module.exports.buildCategoryWhere = (settings, search) => {
  const { acceptLanguage } = settings;

  if (search?.catId) {
    return `id="${search.catId}"`;
  }

  if (search?.slug) {
    const predicate = acceptLanguage.map(locale => `${locale}="${search.slug}"`).join(' or ');
    return `slug(${predicate})`;
  }

  if (search?.key) {
    return `key="${search.key}"`;
  }

  return undefined;
};

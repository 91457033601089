
























































































import {
  AisAutocomplete,
  AisConfigure,
  AisInstantSearch
} from 'vue-instantsearch';
import { createServerRootMixin } from 'vue-instantsearch/src/instantsearch';
import { ref, defineComponent } from '@nuxtjs/composition-api';
import type { HitResultItem } from 'vue-instantsearch';
import { getAlgoliaClient } from '@/helpers/algolia/algolia';
import { useRouteExtended } from '~/composables';
import { ITEM_COUNT_FOR_QUICK_SEARCH, ALGOLIA_GLOBAL_STATE_NAME } from '~/constants/algolia';
import { ROUTES } from '~/constants/routes';
import { LinkType } from '~/types/components/Link/LinkType';
import { algoliaSSRRequiredFields } from '~/mixins/algoliaSSRRequiredFields';

export default defineComponent({
  name: 'SearchBar',
  components: {
    AisAutocomplete,
    AisConfigure,
    AisInstantSearch,
    SearchBarResults: () => import(/* webpackChunkName: "SearchBarResults" */
      '~/components/molecules/SearchBarResults.vue')
  },
  mixins: [algoliaSSRRequiredFields(ALGOLIA_GLOBAL_STATE_NAME.ALGOLIA_STATE_SEARCHBAR)],
  props: {
    languageAndCountry: {
      type: String,
      default: null
    },
    mainSearchIndex: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit, refs, root: { $router } }) {
    const { getAdjustedSlug } = useRouteExtended();

    const DEFAULT_ACTIVE_INDEX: number = -1;
    const activeIndex = ref<number>(DEFAULT_ACTIVE_INDEX);
    const currentValue = ref<{ name: string; url: string; }>({ name: '', url: '' });
    const isSearchResultsOpen = ref<boolean>(false);
    const MIN_CHARS_FOR_SEARCH = 3;

    const updateSearch = (value: string, refine: (string) => void) => {
      if (!value) {
        currentValue.value.name = '';
        closeSearchResults();
        if (refs.searchInput instanceof HTMLElement) {
          refs.searchInput?.focus();
        }
      }
      if (value.length < MIN_CHARS_FOR_SEARCH) {
        return;
      }
      refine(value);
      openSearchResults();
      currentValue.value = { name: value, url: '' };
    };

    const onSearch = (currentRefinement: string) => {
      emit('search', currentValue.value.name);
      if (currentRefinement && currentRefinement !== currentValue.value.name) {
        $router.push(currentValue.value.url);
      } else {
        $router.push(`${getAdjustedSlug(ROUTES.SEARCH)}?query=${currentRefinement}`);
      }
      closeSearchResults();
    };

    const setCurrentValue = (value: HitResultItem) => {
      currentValue.value = { name: value.name, url: value.url };
    };

    const onArrowUp = (hits: HitResultItem[]) => {
      if (activeIndex.value < 1) {
        activeIndex.value = hits.length - 1;
      } else {
        activeIndex.value--;
        setCurrentValue(hits[activeIndex.value]);
      }
    };

    const onArrowDown = (hits: HitResultItem[]) => {
      if (activeIndex.value + 1 < hits.length) {
        activeIndex.value++;
        setCurrentValue(hits[activeIndex.value]);
      } else {
        activeIndex.value = 0;
      }
    };

    const openSearchResults = () => {
      isSearchResultsOpen.value = true;
    };

    const closeSearchResults = () => {
      isSearchResultsOpen.value = false;
    };

    const onProductClicked = (product: HitResultItem, searchTerm: string) => {
      closeSearchResults();
      emit('product-clicked', { product, searchTerm });
    };

    const onSearchResultsShown = (products: HitResultItem[]) => {
      emit('search-results-shown', products);
    };

    setCurrentValue({ name: '', url: '' });

    const searchClient = getAlgoliaClient(props.languageAndCountry);

    const mixin = createServerRootMixin({
      searchClient,
      indexName: props.mainSearchIndex
    });

    const clickedOnSearchBar = () => {
      emit('search-bar-clicked');
    };

    return {
      isSearchResultsOpen,
      onSearch,
      searchClient,
      ITEM_COUNT_FOR_QUICK_SEARCH,
      updateSearch,
      onArrowUp,
      onArrowDown,
      activeIndex,
      currentValue,
      closeSearchResults,
      openSearchResults,
      LinkType,
      onProductClicked,
      onSearchResultsShown,
      clickedOnSearchBar,
      ...mixin.data()
    };
  }
});


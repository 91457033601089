import config from '~/config';
import { SHOW_CONFIRMATION } from '~/constants/queryOptions';

export const generateWebformEndpoint = ({
  domain,
  orgId
}: {
  domain: string;
  orgId: string;
}) => {
  if (!domain || !orgId) {
    throw new Error('Both domain and orgId are required.');
  }

  const url = new URL(domain);
  const params = new URLSearchParams({
    encoding: config.SALESFORCE_ACCEPTED_CHARACTER_SET,
    orgId
  });

  url.search = params.toString();

  return url.toString();
};

export const getCurrentUrlWithRedirectionFlag = (): string => {
  if (typeof window !== 'object') {
    return '';
  }

  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.append(SHOW_CONFIRMATION, 'true');

  return currentUrl.toString();
};

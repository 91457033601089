const { updateCartBase } = require('../updateCartBase');
const { setDefaultShippingMethod } = require('../setShippingMethod/index');

/**
 * Updates user cart using provided actions
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Cart and update actions information
 * @param customQuery - Custom queries included in the request
 * @returns User cart information
 */
module.exports.updateCart = async (context, params, customQuery) => {
  const updateCartResponse = await updateCartBase(context, params, customQuery);
  const cart = updateCartResponse.data.cart;
  const setShippingMethodResponse = await setDefaultShippingMethod(context, cart, customQuery);

  return setShippingMethodResponse || updateCartResponse;
};

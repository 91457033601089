import type { LineItem, Order } from '@vsf-enterprise/commercetools-types';

function getDate(order: Order | null): string {
  return order?.createdAt || '';
}

function getId(order: Order | null): string {
  return order?.id || '';
}

function getStatus(order: Order | null): string {
  return order?.orderState || '';
}

function getPrice(order: Order | null): number {
  return order?.totalPrice?.centAmount ? order.totalPrice.centAmount / 100 : 0;
}

function getItems(order: Order | null): LineItem[] {
  return order?.lineItems || [];
}

function getItemSku(item: LineItem | null): string {
  return item?.productId || '';
}

function getItemName(item: LineItem): string {
  return item.name ?? '';
}

function getItemQty(item: LineItem | null): number {
  return item?.quantity || 0;
}

function getItemPrice(item: LineItem | null): number {
  return item?.price?.value.centAmount ? item.price.value.centAmount / 100 : 0;
}

function getFormattedPrice(price: number): number {
  return price;
}

function getOrdersTotal(orders: { total: number }): number {
  return orders.total;
}

export const orderGetters = {
  getDate,
  getId,
  getStatus,
  getPrice,
  getItems,
  getItemSku,
  getItemName,
  getItemQty,
  getItemPrice,
  getFormattedPrice,
  getOrdersTotal
};

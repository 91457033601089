









import { defineComponent, computed, useRoute } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { Category } from '@vsf-enterprise/commercetools-types';
import { COMPONENT_CACHING } from '~/constants/queryOptions';
import { useI18n } from '~/composables';
import AppFooter from '~/components/organisms/Footer/AppFooter/AppFooter.vue';

export default defineComponent({
  name: 'AppFooterWrapper',
  components: { AppFooter },
  props: {
    categoriesContainer: {
      type: Array as PropType<Category[]>,
      default: () => {
        return [];
      }
    }
  },
  setup() {
    const route = useRoute();
    const componentCaching = computed(() => route.value?.query?.[COMPONENT_CACHING]);
    const { countryLocalization, languageAndCountry } = useI18n();
    const topLevelDomain = computed(() => countryLocalization.value.topLevelDomain);

    return {
      componentCaching,
      languageAndCountry,
      topLevelDomain
    };
  }
});

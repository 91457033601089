const { gql } = require('graphql-tag');
const PRODUCT_CONSTANTS = require('../../../../../constants/products.js');

module.exports = {
  fragment: gql`
    fragment Attributes on ProductVariant {
      attributesRaw(includeNames:[
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.BRAND}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.BRUTTO_HEIGHT}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.BRUTTO_LENGTH}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.BRUTTO_WEIGHT}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.BRUTTO_WIDTH}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.DELIVERY_PACKAGE}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.DESCRIPTION}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.ELIGIBLE_FOR_SELLING}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.FAQ}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.HIGHLIGHTS}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.IS_PACKAGE_TYPE_FREIGHT}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.MANUAL_URL}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.MODEL}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.NETTO_HEIGHT}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.NETTO_LENGTH}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.NETTO_WEIGHT}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.NETTO_WIDTH}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.NUMBER_OF_360_IMAGES}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.PARENT_PRODUCTS_FOR_ACCESSORY}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.RELATED_ACCESSORIES}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.RELEASE_DATE}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.STANDARD_PRICE}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.TAGS}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.TECH_DETAILS}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.VIDEO}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.VOLUME_DISCOUNT_GROUP}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.ENERGY_RATING_LABEL}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.ENERGY_RATING_FICHE}",
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.ENERGY_RATING_NESTED_LABEL}"
        "${PRODUCT_CONSTANTS.PRODUCT_ATTRIBUTES.REFERENCED_RESOURCE_SET}"
      ]) {
        name
        value
        attributeDefinition {
          type {
            name
          }
          label(locale: $locale)
        }
      }
    }
  `
};

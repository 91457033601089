const {
  KEY_IS_NOT_DEFINED,
  DISCOUNT_FIELDS
} = require('../../../constants/commerceTools');
const { buildWhereClause } = require('./buildWhereClause');

/**
 * @param {string} discountGroup
 * @param {string} storeKey
 * @returns {string}
 */
const buildVolumeDiscountsWhereClause = (discountGroup, storeKey) => {
  const isActive = `${DISCOUNT_FIELDS.IS_ACTIVE}=true`;
  const isInCurrentStore = `${DISCOUNT_FIELDS.STORES}(${buildWhereClause([storeKey], 'key')})`;
  const storeIsNotDefined = `${DISCOUNT_FIELDS.STORES}(${KEY_IS_NOT_DEFINED})`;

  const groupWhereClause = buildWhereClause([discountGroup], DISCOUNT_FIELDS.VOLUME_DISCOUNT_GROUP);
  const isCurrentGroup = `custom(fields(${groupWhereClause}))`;

  return `${isActive} and (${isInCurrentStore} or ${storeIsNotDefined}) and (${isCurrentGroup})`;
};

module.exports.buildVolumeDiscountsWhereClause = buildVolumeDiscountsWhereClause;

const { CATEGORY_CUSTOM_FIELDS } = require('#~/constants/commerceTools.js');

const RELATED_CATEGORIES_FIELDS = [
  CATEGORY_CUSTOM_FIELDS.RELATED_CATEGORIES_1,
  CATEGORY_CUSTOM_FIELDS.RELATED_CATEGORIES_2,
  CATEGORY_CUSTOM_FIELDS.RELATED_CATEGORIES_3
];

module.exports.buildRelatedCategoriesQuery = () => RELATED_CATEGORIES_FIELDS.map((field, index) => `
  relatedCategories${index + 1}: customFieldsRaw(includeNames: ["${field}"]) {
    ...RelatedCategoriesCustomField
  }
`).join('');

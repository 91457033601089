




































import { defineComponent, ref, onMounted } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { Category } from '@vsf-enterprise/commercetools-types';
import getComponentCacheKey from '~/helpers/caching/getComponentCacheKey';
import Disclaimers from '~/components/molecules/AppFooter/Disclaimers.vue';
import LanguageSelector from '~/components/molecules/LanguageSelector/LanguageSelector.vue';
import { EXPONDO_LOGO_LIGHT_DESKTOP_PATH } from '~/constants/expondoLogoPath';
import { useI18n } from '~/composables/useI18n';
import SalesforceWebform from '~/components/organisms/SalesforceWebform/SalesforceWebform.vue';
import { SHOW_WEBFORM } from '~/constants/queryOptions';
import isStringTrue from '~/helpers/dataStructure/isStringTrue';
import FooterLinkColumns from '~/components/molecules/AppFooter/FooterLinkColumns/FooterLinkColumns.vue';

export default defineComponent({
  name: 'AppFooter',
  components: {
    Disclaimers,
    LanguageSelector,
    SalesforceWebform,
    FooterLinkColumns
  },
  props: {
    componentCaching: {
      type: String,
      default: ''
    },
    topLevelDomain: {
      type: String,
      required: true
    },
    languageAndCountry: {
      type: String,
      required: true
    },
    categoriesContainer: {
      type: Array as PropType<Category[]>,
      default: () => {
        return [];
      }
    }
  },
  serverCacheKey(props: {
    copyrightLinks: Object,
    componentCaching: string,
    topLevelDomain: string,
    languageAndCountry: string
  }) {
    return getComponentCacheKey({
      componentCaching: props.componentCaching,
      topLevelDomain: props.topLevelDomain,
      languageAndCountry: props.languageAndCountry
    });
  },
  setup() {
    const isContactFormVisible = ref(false);
    const shouldShowWebform = ref(false);

    const { countryLocalization } = useI18n();

    onMounted(() => {
      const urlParams = typeof window === 'object' ? new URLSearchParams(window.location.search) : null;
      shouldShowWebform.value = isStringTrue(process.env.salesforceWebformActive) ||
        (urlParams && !!(urlParams.get(SHOW_WEBFORM)));
    });

    const toggleContactFormVisibility = () => {
      isContactFormVisible.value = !isContactFormVisible.value;
    };

    return {
      EXPONDO_LOGO_LIGHT_DESKTOP_PATH,
      countryLocalization,
      isContactFormVisible,
      shouldShowWebform,
      toggleContactFormVisibility
    };
  }
});

// import { useIntegrations } from '~/composables';
import { useVSFContext } from '@vue-storefront/core';
import { mapPaginationParams } from '~/utils/ct/composables/helpers/apiHelpers';
import { ApiParams } from '~/utils/ct';

export function useProductApi() {
  // old way
  const { $ct } = useVSFContext();
  // new way
  // const { $ct } = useIntegrations();

  const search = ({ customQuery, ...searchParams }: ApiParams) => {
    const apiSearchParams = {
      ...searchParams,
      ...mapPaginationParams(searchParams)
    };
    return $ct.api.getProduct(apiSearchParams, customQuery);
  };

  return {
    search
  };
}

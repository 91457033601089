import { computed } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';

type HasTranslationConfig = {
  checkFallbacks: boolean;
};

export const useTranslationCheck = () => {
  const { i18n } = useVSFContext();

  const getFallbackLocale = (currentLocale: string, fallbackLocaleObject: Record<string, string[]>) => {
    for (const [locale, fallbacks] of Object.entries(fallbackLocaleObject)) {
      if (locale === currentLocale) {
        return fallbacks[0];
      }
    }
    return null;
  };

  const hasTranslation = (translationKey: string, config?: HasTranslationConfig): boolean => {
    const fallbackLocale = getFallbackLocale(i18n.locale, i18n.fallbackLocale);

    if (i18n.te(translationKey)) {
      return true;
    }

    if (config?.checkFallbacks) {
      return i18n.te(translationKey, fallbackLocale);
    }

    return false;
  };

  return {
    hasTranslation,
    locale: computed(() => i18n.locale)
  };
};

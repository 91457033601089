import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { catchApiErrors } from '~/utils/ct/composables/helpers/apiHelpers';
import { ApiResponse } from '~/utils/ct/types';

export default (instance: { services: { axios: NuxtAxiosInstance }}, url: string) => {
  const { axios } = instance.services;

  return async <T = unknown>(params: unknown) => {
    const response: ApiResponse<T> = await axios.post(url, params);
    catchApiErrors(response);
    return response?.data;
  };
};

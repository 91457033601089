const { gql } = require('graphql-tag');

const DefaultCustomer = require('../fragments/defaultCustomer').fragment;

/** Extending the query in order to get user (customer) ID from response */
/** Change in DefaultCustomer fragment */
module.exports = gql`
  ${DefaultCustomer}

  mutation customerUpdateMe($version: Long!, $actions: [MyCustomerUpdateAction!]!, $storeKey: KeyReferenceInput) {
    user: updateMyCustomer(version: $version, actions: $actions, storeKey: $storeKey) {
      ...DefaultCustomer
    }
  }
`;

























import { computed, defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import type { FooterLink } from '~/types/cms/Footer/FooterLink/FooterLink';
import Link from '~/components/atoms/Link/Link.vue';
import { LinkType } from '~/types/components/Link/LinkType';
import { useRouteExtended } from '~/composables';
import { instanceOfFooterLink } from '~/types/cms/Footer/FooterLink/instanceOfFooterLink';
import isExternalUrl from '~/helpers/isExternalUrl/isExternalUrl';
import { ROUTES_INFORMATION } from '~/constants/routes';
import { HELP_CENTER_TRANSLATION_KEY } from '~/constants/faq';

export default defineComponent({
  name: 'FooterLinkItem',
  components: {
    Link
  },
  props: {
    link: {
      type: Object as PropType<FooterLink>,
      default: (): FooterLink => ({}),
      validator: (link: FooterLink) => {
        return !!instanceOfFooterLink(link);
      }
    }
  },
  setup(props) {
    const { i18n } = useVSFContext();
    const { getAdjustedSlug } = useRouteExtended();
    const url = computed(() => {
      if (props.link.routeKey) {
        const slug = ROUTES_INFORMATION[props.link.routeKey].SLUG;
        if (isExternalUrl(slug)) {
          return slug;
        }
        if (slug === HELP_CENTER_TRANSLATION_KEY) {
          return i18n.t(slug);
        }

        return getAdjustedSlug(props.link.routeKey);
      }

      if (props.link.url) {
        return props.link.url;
      }

      return '';
    });
    const name = computed(() => props.link.translationKey ? i18n.t(props.link.translationKey) : props.link.name);
    const showLink = computed(() => !!(url.value && name.value));

    return {
      LinkType,
      url,
      name,
      showLink
    };
  }
});

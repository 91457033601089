const createMyOrderFromCartMutation = require('../queries/cart/createMyOrderFromCart.js');
const cartwebSocketHelper = require('../../../helpers/webSocket.js');
const { isWebSocketEnabledForCountry } = require('../../../helpers/locales/isWebSocketEnabledForCountry');
const getStoreKey = require('./getStoreKey.js');
const { clientMutate } = require('./queries');

/**
 * Endpoint for creating order from cart of the current user
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param draft - Cart details
 * @param customQuery - Custom queries included in the request
 * @returns Order information
 */
module.exports.createMyOrderFromCart = async (context, draft, customQuery) => {
  const { locale, acceptLanguage, currency, store, country } = context.config;
  const defaultVariables = {
    locale,
    acceptLanguage,
    currency,
    draft,
    ...getStoreKey(store)
  };
  const { createMyOrderFromCart } = context.extendQuery(customQuery, {
    createMyOrderFromCart: {
      query: createMyOrderFromCartMutation,
      variables: defaultVariables
    }
  });
  if (isWebSocketEnabledForCountry(country)) {
    cartwebSocketHelper.sendCartUpdate({ data: null, cartId: draft.id });
  }
  return await clientMutate(context, {
    mutation: createMyOrderFromCart.query,
    variables: createMyOrderFromCart.variables
  });
};

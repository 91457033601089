export const ButtonSizesArray = ['sm', 'md', 'lg'] as const;
export type ButtonSizes = typeof ButtonSizesArray[number];

// TODO - Convert enum approach to constant objects - INSP-824
export const ButtonTypesArray = [
  'primary',
  'secondary',
  'tertiary',
  'quatentary',
  'quinary',
  'link',
  'highlight',
  'raw'
] as const;
export type ButtonType = typeof ButtonTypesArray[number];

export interface ButtonInterface {
  type?: String | ButtonType;
  text: string;
  size?: ButtonSizes;
  url?: string;
}

export const ButtonDefaults: ButtonInterface = {
  type: 'primary',
  text: '',
  size: 'lg',
  url: ''
};

/**
 * @param {Record<string, any>} inputObject
 * @param {string[]} filterArray
 * @returns Record<string, any>
 */
module.exports.filterObjectByArray = (inputObject, filterArray) => {
  return Object.keys(inputObject)
    .filter(key => filterArray.includes(key))
    .reduce((newObj, key) => {
      newObj[key] = inputObject[key];
      return newObj;
    }, {});
};

const { gql } = require('graphql-tag');

module.exports = {
  fragment: gql`
    fragment DefaultCartDiscountValue on CartDiscountValue {
      type
      ... on AbsoluteDiscountValue {
        money {
          currencyCode
          centAmount
          fractionDigits
        }
      }
      ... on RelativeDiscountValue {
        permyriad
      }
      ...on FixedPriceDiscountValue {
        money {
          centAmount
        }
      }
      ...on GiftLineItemValue {
        variantId
      }
    }
  `
};

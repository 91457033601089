const { gql } = require('graphql-tag');

const DefaultLineItem = require('../fragments/defaultLineItem').fragment;
const DefaultAddress = require('../fragments/defaultAddress').fragment;
const DefaultCustomer = require('../fragments/defaultCustomer').fragment;
const DefaultShippingMethod = require('./shipping/defaultShippingMethod').fragment;
const DefaultCartDiscountValue = require('./cartDiscount/defaultCartDiscountValue').fragment;

/* Added discounts information */
module.exports = {
  fragment: gql`
    ${DefaultShippingMethod}
    ${DefaultLineItem}
    ${DefaultAddress}
    ${DefaultCustomer}
    ${DefaultCartDiscountValue}

    fragment DefaultCart on Cart {
      id
      customerId
      customerEmail
      lineItems {
        ...DefaultLineItem
      }
      totalPrice {
        centAmount
      }
      shippingAddress {
        ...DefaultAddress
      }
      billingAddress {
        ...DefaultAddress
      }
      customer {
        ...DefaultCustomer
      }
      totalPrice {
        centAmount
      }
      taxedPrice {
        totalNet {
          centAmount
        }
        totalGross {
          centAmount
        }
        taxPortions {
          rate
          amount {
            centAmount
          }
          name
        }
      }
      paymentInfo {
        payments {
          id
        }
      }
      shippingInfo {
        price {
          centAmount
        }
        taxedPrice {
          totalGross {
            centAmount
          }
        }
        shippingMethodState
        discountedPrice {
          value {
            centAmount
          }
          includedDiscounts {
            discountedAmount {
              centAmount
            }
            discount {
              name(acceptLanguage: $acceptLanguage)
              value {
                ...DefaultCartDiscountValue
              }
            }
          }
        }
        shippingMethod {
          ...DefaultShippingMethod
        }
      }

      discountCodes {
        state
        discountCode {
          id
          code
          cartDiscounts {
            validFrom
            validUntil
            sortOrder
            isActive
            stackingMode
            id
            name(locale: $locale)
            description(locale: $locale)
            value {
              ...DefaultCartDiscountValue
            }
          }
        }
      }

      refusedGifts {
        isActive
        validFrom
        validUntil
        name(acceptLanguage: $acceptLanguage)
      }
      custom {
        customFieldsRaw {
          name
          value
        }
      }
      cartState
      taxMode
      version
      inventoryMode
      store {
        key
      }
    }
  `
};

const { gql } = require('graphql-tag');

module.exports = gql`
query getVolumeDiscounts($where: String!, $sort: [String!]) {
  cartDiscounts(where: $where, sort: $sort) {
    results {
      key
      id
      isActive,
      value {
        type
        ...on RelativeDiscountValue {
          permyriad
        }
      }
      target {
        type
        ... on LineItemsTarget {
          predicate
        }
      }
    }
  }
}
`;

import { Logger } from '@vue-storefront/core';
import { computed, useRoute } from '@nuxtjs/composition-api';
import { useIntegrations } from '~/composables';
import { RecommendationAction } from '~/constants/recommendationRequest';
import {
  BLOOMREACH_EVENT_NAME,
  BLOOMREACH_TYPE_NAME,
  BloomreachTypeId,
  BloomreachTypeName
} from '~/types/localization/localizationHelperTypes';
import { BloomreachProduct } from '~/types/product/BloomreachProduct';
import { BloomreachIntegration } from '~/types/integrations/bloomreach/BloomreachIntegration';
import { errorToString } from '~/helpers/error/errorToString';

const getRecommendationId =
  ($bloomreach: BloomreachIntegration, recommendationType?: BloomreachTypeName): BloomreachTypeId => {
    return (recommendationType && $bloomreach.state.bloomreachDetails[recommendationType]) ||
      $bloomreach.state.bloomreachDetails[BLOOMREACH_TYPE_NAME.SIMILAR_PRODUCTS] ||
      '';
  };

export const useRecommendationEvent = ({
  path,
  recommendationType
}:
  {
    path?: string;
    recommendationType?: BloomreachTypeName
  } = {}) => {
  const { $tagManager, $cia, $bloomreach } = useIntegrations();
  const route = useRoute();
  const recommendationId = computed(() => getRecommendationId($bloomreach, recommendationType));

  const logIntegrationError = (forIntegration: string, error: string) => {
    Logger.error(`${forIntegration} error: ${error}`);
  };

  const triggerCiaRecommendationEvent = (action: RecommendationAction) => {
    try {
      $cia.event.recommendation({
        recommendationId: recommendationId.value,
        recommendationName: BLOOMREACH_EVENT_NAME[recommendationType || BLOOMREACH_TYPE_NAME.SIMILAR_PRODUCTS],
        path: path || route.value.path || '',
        action
      });
    } catch (error: unknown) {
      logIntegrationError('cia|triggerCiaRecommendationEvent', errorToString(error));
    }
  };

  const triggerGtmRecommendationEvent = ({
    suggestedProducts
  }: {
    suggestedProducts: BloomreachProduct[]
  }) => {
    try {
      $tagManager.events.triggerRecommendationsShownTags(suggestedProducts, recommendationType);
    } catch (error: unknown) {
      logIntegrationError('gtm|triggerGtmRecommendationEvent', errorToString(error));
    }
  };

  return {
    triggerCiaRecommendationEvent,
    triggerGtmRecommendationEvent
  };
};

import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { getAxiosConfig } from '~/helpers/axios/getAxiosConfig';
import { setAxiosInterceptors } from '~/helpers/axios/setAxiosInterceptors';
import createRequest from '~/plugins/integrations/ct/requests/createRequest';
import { extendApiMethods } from '~/middlewareExtensions/commercetools/extension';
export const ctBase = 'ct';

export default (
  { $axios, $ctOld }: { $axios: NuxtAxiosInstance, $ctOld: Context['$ct'] }
) => {
  const axios = $axios.create(
    getAxiosConfig((process?.server ? $axios.defaults.baseURL : '/') + ctBase)
  );

  setAxiosInterceptors({ axios, shouldRejectPromise: true });

  const services = {
    axios
  };
  const axiosRequests: Record<string, Function> = {};
  Object.keys(extendApiMethods).forEach((request) => {
    axiosRequests[request] = createRequest({ services }, request);
  });

  const apiProxyHandler = {
    get(target: never[], propertyKey: string, receiver?: unknown) {
      if (propertyKey in axiosRequests) {
        return axiosRequests[propertyKey];
      }
      return Reflect.get(target, propertyKey, receiver);
    }
  };

  const api = new Proxy($ctOld.api, apiProxyHandler);
  return {
    services,
    api
  };
};

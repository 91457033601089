const { gql } = require('graphql-tag');

const CustomerFragment = require('../../fragments/defaultCustomer').fragment;
const CartFragment = require('../../fragments/defaultCart').fragment;

module.exports = gql`
  ${CartFragment}
  ${CustomerFragment}

  query getFullProfile($locale: Locale!, $acceptLanguage: [Locale!], $currency: Currency!) {
    me {
      activeCart {
        ...DefaultCart
      }
      customer {
        ...DefaultCustomer
      }
    }
  }
`;

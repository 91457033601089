const { Logger } = require('@vue-storefront/core');
const getVolumeDiscountsQuery = require('../queries/cart/getVolumeDiscountsQuery');
const { clientQuery } = require('../helpers/queries');
const { buildVolumeDiscountsWhereClause } = require('../helpers/buildVolumeDiscountsWhereClause');

/**
 * @param {import('@nuxt/types').Context} context
 * @param {{ discountGroup: string, sort: string[] }} params
 */

module.exports.getVolumeDiscounts = async (context, { discountGroup, sort }) => {
  const { store } = context.config;
  if (!store || !discountGroup) {
    return;
  }

  try {
    const discountWhere = buildVolumeDiscountsWhereClause(discountGroup, store);
    return await clientQuery(context, {
      query: getVolumeDiscountsQuery,
      variables: {
        where: discountWhere,
        sort
      }
    });
  } catch (e) {
    Logger.error(e);
  }
};

const { gql } = require('graphql-tag');
/* Custom query, not overriding anything */
module.exports = gql(`
  query inventoryEntries($where: String) {
    inventoryEntries(where: $where) {
      offset,
      count,
      total,
      results {
        availableQuantity,
        sku
      }
    }
  }
`);

const { gql } = require('graphql-tag');

const DefaultAddress = require('../fragments/defaultAddress').fragment;

/*
 * Added fetching customer id
 */
module.exports = {
  fragment: gql`
    ${DefaultAddress}

    fragment DefaultCustomer on Customer {
      version
      firstName
      lastName
      email,
      vatId,
      locale,
      id
      addresses {
        id
      }
      shippingAddresses {
        ...DefaultAddress
      }
      billingAddresses {
        ...DefaultAddress
      }
      defaultBillingAddressId
      defaultShippingAddressId
    }`
};

const { gql } = require('graphql-tag');
const DefaultLineItem = require('../fragments/defaultLineItem').fragment;
const DefaultAddress = require('../fragments/defaultAddress').fragment;

module.exports = gql`
  ${DefaultLineItem}
  ${DefaultAddress}

  mutation createMyOrderFromCart($draft: OrderMyCartCommand!, $locale: Locale!, $acceptLanguage: [Locale!],
    $currency: Currency!, $storeKey: KeyReferenceInput) {

    order: createMyOrderFromCart(draft: $draft, storeKey: $storeKey) {
      lineItems {
        ...DefaultLineItem
      }
      totalPrice {
        centAmount
        currencyCode
      }
      orderState
      id
      orderNumber
      version
      createdAt
      customerEmail
      shipmentState
      paymentState
      shippingAddress {
        ...DefaultAddress
      }
      billingAddress {
        ...DefaultAddress
      }
      cart {
        id
        version
      }
    }
  }
`;

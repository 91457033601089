const { gql } = require('graphql-tag');

/* No changes */
module.exports = {
  fragment: gql`
    fragment DefaultShippingMethod on ShippingMethod {
      id
      version
      name
      isDefault
      localizedDescription(acceptLanguage: $acceptLanguage)
      key
      custom {
        customFieldsRaw(includeNames:["DeliveryTime"]) {
          name
          value
        }
      }
      zoneRates {
        zone {
          id
          name
        }
        shippingRates {
          freeAbove {
            type
            centAmount
            currencyCode
          }
          isMatching
          price {
            centAmount
            currencyCode
          }
        }
      }
    }
  `
};































































import { computed, defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { Category } from '@vsf-enterprise/commercetools-types';
import { useVSFContext } from '@vue-storefront/core';
import Social from '~/components/atoms/SocialLinks.vue';
import FooterLinkColumn from '~/components/molecules/AppFooter/FooterLinkColumn/FooterLinkColumn.vue';
import PhoneNumber from '~/components/atoms/PhoneNumber.vue';
import { PHONE_NUMBER_KEYS } from '~/constants/phoneNumber';
import { LINK_TYPE_HREF } from '~/constants/html';
import { getFooterLinks } from '~/helpers/footer/getFooterLinks';
import TrustedPaymentsWrapper from '~/components/molecules/TrustedPaymentsWrapper/TrustedPaymentsWrapper.vue';
import ExternalLink from '~/components/atoms/Link/ExternalLink/ExternalLink.vue';
import config from '~/config';

export default defineComponent({
  name: 'FooterLinkColumns',
  components: {
    Social,
    FooterLinkColumn,
    PhoneNumber,
    TrustedPaymentsWrapper,
    ExternalLink
  },
  props: {
    shouldShowWebform: {
      type: Boolean,
      default: false
    },
    categoriesContainer: {
      type: Array as PropType<Category[]>,
      default: () => {
        return [];
      }
    }
  },
  setup(props, { emit }) {
    const { i18n } = useVSFContext();

    const footerLinks = computed(() => getFooterLinks(props.categoriesContainer));

    const phoneExists = i18n.te(PHONE_NUMBER_KEYS.CUSTOMER_CARE) && i18n.t(PHONE_NUMBER_KEYS.CUSTOMER_CARE);
    const emailExists = i18n.te('shopAdvisor.email') && i18n.t('shopAdvisor.email');

    const showServiceClient = phoneExists || emailExists;

    const toggleContactForm = () => {
      emit('toggleContactForm');
    };

    return {
      PHONE_NUMBER_KEYS,
      LINK_TYPE_HREF,
      showServiceClient,
      phoneExists,
      emailExists,
      footerLinks,
      returnPortalUrl: config.RETURN_PORTAL_URL,
      toggleContactForm
    };
  }
});

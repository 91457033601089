const { Logger } = require('@vue-storefront/core');
const WebSocket = require('ws');
const { webSocketPort } = require('../config/shared');
const { WEBSOCKET_EVENT, WEBSOCKET_CONFIG } = require('../constants/webSocket');
const { SECURE_WEBSITE_PROTOCOLE_PREFIX } = require('../constants/http');

const clientConnections = new Map();

const startConnection = () => {
  const webSocketServer = new WebSocket.Server({ port: webSocketPort });

  webSocketServer.on(WEBSOCKET_EVENT.CONNECTION, (clientSocket, clientRequest) => {
    try {
      const url = new URL(clientRequest.url, `${SECURE_WEBSITE_PROTOCOLE_PREFIX}${clientRequest.headers.origin}`);
      const cartId = url.searchParams.get(WEBSOCKET_CONFIG.CART_ID);

      if (!cartId) {
        Logger.error('WebSocket Error: No cart ID found in the connection request');
        clientSocket.close();
        return;
      }

      // Check if the cartId has reached the connection limit
      if (countCartConnections(cartId) >= WEBSOCKET_CONFIG.MAX_CONNECTIONS_PER_CART) {
        Logger.warn(`Cart ID ${cartId} has reached the maximum number of concurrent connections`);
        clientSocket.close();
        return;
      }

      addClientConnection(cartId, clientSocket);

      clientSocket.on(WEBSOCKET_EVENT.CLOSE, () => {
        removeClientConnection(cartId, clientSocket);
      });

      clientSocket.on(WEBSOCKET_EVENT.ERROR, (error) => {
        Logger.error(`WebSocket Error for cart ID ${cartId}: ${error.message}`);
      });
    } catch (error) {
      Logger.error('WebSocket Error: Error processing connection:', error.message);
      clientSocket.close();
    }
  });

  webSocketServer.on(WEBSOCKET_EVENT.ERROR, (error) => {
    Logger.error('WebSocket Server Error:', error.message);
  });
};

// Count the number of active connections for a specific cart ID
const countCartConnections = (cartId) => {
  if (!clientConnections.has(cartId)) {
    return 0;
  }
  return clientConnections.get(cartId).length;
};

const addClientConnection = (cartId, clientSocket) => {
  if (!clientConnections.has(cartId)) {
    clientConnections.set(cartId, []);
  }

  const connections = clientConnections.get(cartId);
  connections.push(clientSocket);
};

const removeClientConnection = (cartId, clientSocket) => {
  const connections = clientConnections.get(cartId);
  if (!connections) {
    return;
  }
  const updatedConnections = connections.filter(client => client !== clientSocket);
  if (updatedConnections.length > 0) {
    clientConnections.set(cartId, updatedConnections);
  } else {
    clientConnections.delete(cartId);
  }
};

const deleteUserConnections = (cartId) => {
  if (clientConnections.has(cartId)) {
    const connections = clientConnections.get(cartId);
    connections.forEach(clientSocket => clientSocket.close());
    clientConnections.delete(cartId);
  }
};

const sendMessage = ({ event, cartId, data }) => {
  const message = JSON.stringify({ event, data });
  const connections = clientConnections.get(cartId);

  if (!connections) {
    return;
  }

  connections.forEach(clientSocket => {
    if (clientSocket.readyState === WebSocket.OPEN) {
      try {
        clientSocket.send(message, (error) => {
          if (error) {
            Logger.error(`WebSocket Error: Failed to send message to cart ID ${cartId}: ${error.message}`);
          }
        });
      } catch (error) {
        Logger.error(`WebSocket Error: Unexpected error while sending message to cart ID ${cartId}: ${error.message}`);
      }
    }
  });
};

const sendCartUpdate = ({ data, cartId }) => {
  sendMessage({ event: WEBSOCKET_EVENT.CART_UPDATE, data, cartId });
};

const sendUpdatedStock = ({ data, cartId }) => {
  sendMessage({ event: WEBSOCKET_EVENT.UPDATED_STOCK, data, cartId });
};

module.exports = {
  startConnection,
  sendCartUpdate,
  deleteUserConnections,
  sendUpdatedStock,
  clientConnections
};

import { useRoute, useRouter } from '@nuxtjs/composition-api';
import { sharedRef } from '@vue-storefront/core';
import { SHOW_CONFIRMATION } from '~/constants/queryOptions';
import { StringBoolean } from '~/types/StringBoolean';

export const useConfirmationPopup = () => {
  const route = useRoute();
  const router = useRouter();

  const isConfirmationPopupOpen = sharedRef(false, 'isConfirmationPopupOpen');

  const removeConfirmationFlagFromQuery = () => {
    const currentQuery = { ...route.value.query };
    Reflect.deleteProperty(currentQuery, SHOW_CONFIRMATION);
    router.replace({
      query: currentQuery
    });
  };

  const openConfirmationPopup = () => {
    isConfirmationPopupOpen.value = true;
  };
  const closeConfirmationPopup = () => {
    isConfirmationPopupOpen.value = false;
  };

  const checkIfShouldShowConfirmationPopup = () => {
    if (route.value?.query?.[SHOW_CONFIRMATION] !== StringBoolean.true) {
      return;
    }
    removeConfirmationFlagFromQuery();
    openConfirmationPopup();
  };

  return {
    checkIfShouldShowConfirmationPopup,
    isConfirmationPopupOpen,
    openConfirmationPopup,
    closeConfirmationPopup
  };
};

import { useVSFContext } from '@vue-storefront/core';
import type { HitResultItem } from 'vue-instantsearch';
import type { LineItem } from '@vsf-enterprise/commercetools-types';
import {
  useCookies,
  useIntegrations
} from '~/composables';
import { ALGOLIA_HISTORY } from '~/constants/localStorage';
import { extractGoogleAnalyticsUserIdFromCookie } from '~/helpers/googleAnalytics';
import { i18nToAlgoliaMainIndex } from '~/helpers/locales/i18nCurrentDetails';

type AlgoliaClickHistory = {
    [objectID: string]: { searchTerm: string, index: string }
}

type AlgoliaQueries = {
    [searchTerm: string]: { objectIDs: string[], index: string }
}

export default function () {
  const { googleUserToken, algoliaUserToken } = useCookies();
  const { i18n } = useVSFContext();
  const { $tagManager } = useIntegrations();
  const algoliaIndex = i18nToAlgoliaMainIndex(i18n);
  const userToken = extractGoogleAnalyticsUserIdFromCookie(googleUserToken) || algoliaUserToken;

  function setAlgoliaClickHistory(object: AlgoliaClickHistory) {
    localStorage.setItem(ALGOLIA_HISTORY, JSON.stringify(object));
  }

  function getAlgoliaClickHistory(): AlgoliaClickHistory | null {
    const rawLocalStorage = localStorage.getItem(ALGOLIA_HISTORY);
    if (!rawLocalStorage) {
      return null;
    }
    return JSON.parse(rawLocalStorage);
  }

  function prepareAlgoliaQueries(skus: string[]): AlgoliaQueries {
    const algoliaClickHistory = getAlgoliaClickHistory();

    if (!algoliaClickHistory) {
      return {};
    }

    const queries: AlgoliaQueries = {};
    for (const sku of skus) {
      const clickHistory = algoliaClickHistory[sku];
      if (!clickHistory) {
        continue;
      }

      const { searchTerm, index } = clickHistory;
      const query = queries[searchTerm];

      if (query) {
        query.objectIDs.push(sku);
      } else {
        queries[searchTerm] = { objectIDs: [sku], index };
      }

      delete algoliaClickHistory[sku];
    }
    setAlgoliaClickHistory(algoliaClickHistory);

    return queries;
  }

  function addToAlgoliaClickHistory(
    { objectID, index, searchTerm }:
    { objectID: string, index: string, searchTerm: string }
  ) {
    let algoliaClickHistory = getAlgoliaClickHistory();
    if (!algoliaClickHistory) {
      algoliaClickHistory = { [objectID]: { searchTerm, index } };
    } else {
      algoliaClickHistory[objectID] = { searchTerm, index };
    }
    setAlgoliaClickHistory(algoliaClickHistory);
  }

  function getAlgoliaQueries(products: LineItem[]): AlgoliaQueries {
    const filteredSkus = products?.flatMap((product) => {
      const sku = product.variant?.sku;
      return sku ? [sku] : [];
    }) || [];
    const queries = prepareAlgoliaQueries(filteredSkus);

    return queries;
  }

  function callViewHitsEvent(hits: HitResultItem[]) {
    const filteredObjectIDs = hits?.flatMap((hit) => {
      const objectID = hit.objectID;
      return objectID ? [objectID] : [];
    }) || [];
    $tagManager.events.triggerViewHitsEvent({ userToken, index: algoliaIndex, objectIDs: filteredObjectIDs });
  }

  function callClickHitEvent(item: HitResultItem, searchTerm: string) {
    const { __position: position, objectID } = item;

    if (!objectID) {
      return;
    }

    const eventParameters = {
      userToken,
      index: algoliaIndex,
      objectIDs: [objectID],
      searchTerm,
      positions: position ? [position] : []
    };

    addToAlgoliaClickHistory({ objectID, index: algoliaIndex, searchTerm });
    $tagManager.events.triggerClickHitEvent(eventParameters);
  }

  function callConversionEvent(products: LineItem[]) {
    const queries = getAlgoliaQueries(products);

    for (const [searchTerm, query] of Object.entries(queries)) {
      if (query) {
        const { objectIDs, index } = query;
        $tagManager.events.triggerConversionEvent({ userToken, index, objectIDs, searchTerm });
      }
    }
  }

  return {
    callViewHitsEvent,
    callClickHitEvent,
    callConversionEvent
  };
}

export type CustomQuery = Record<string, string | Record<string, string>>;

export enum AttributeType {
  STRING = 'StringAttribute',
  DATE = 'DateAttribute',
  DATETIME = 'DateTimeAttribute',
  TIME = 'TimeAttribute',
  NUMBER = 'NumberAttribute',
  ENUM = 'EnumAttribute',
  LOCALIZED_ENUM = 'LocalizedEnumAttribute',
  LOCALIZED_STRING = 'LocalizedStringAttribute',
  MONEY = 'MoneyAttribute',
  BOOLEAN = 'BooleanAttribute'
}

export interface Filter {
  type: AttributeType;
  name: string;
  value: unknown;
}
export type PaginationParams = { page?: number, perPage?: number }
export interface ApiParams extends Record<string, unknown> {
  customQuery?: CustomQuery;
}
export interface ProductSearchParams {
  perPage?: number;
  page?: number;
  sort: unknown;
  term?: unknown;
  filters?: Filter[];
  catId?: string | string[];
  skus?: string[];
  slug?: string;
  id?: string;
  ids?: string[];
  customFilters?: string;
  key?: string;
}

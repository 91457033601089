const { VSF_CURRENCY_COOKIE } = require('@vue-storefront/core');
const { getTotalGrossPrice, getTotalCentAmount } = require('../../../helpers/cart/getTotalPricesModule');
const createPaymentMutation = require('../queries/payment/createPayment');
const { orderWithoutEmailId } = require('../../../constants/log');
const { clientMutate } = require('./queries');

/**
 * @param {import('@vsf-enterprise/commercetools-types').Cart} cart
 * @param {Context} context
 * @param {import('@nuxt/types').CustomQuery} customQuery
 * @param {ValueOf<typeof import('../../../constants/customPayment')>} paymentType
 * @returns {any}
 */
const createCustomPayment = (cart, context, customQuery, paymentType) => {
  if (!cart.customerEmail) {
    console.error(orderWithoutEmailId,
      'createCustomPayment with no email with type: ', paymentType, 'cart id: ', cart.id);
  }
  const totalGrossPrice = getTotalGrossPrice(cart);
  const centAmount = getTotalCentAmount(totalGrossPrice);
  const currencyCode = context.req.cookies?.[VSF_CURRENCY_COOKIE];
  const NAME = paymentType;
  const LOCALE = 'EN';
  const defaultVariables = {
    draft: {
      paymentMethodInfo: {
        paymentInterface: NAME,
        name: {
          locale: LOCALE,
          value: NAME
        }
      },
      amountPlanned: {
        currencyCode,
        centAmount
      }
    }
  };
  const { createPayment: createPaymentGql } = context.extendQuery(
    customQuery, {
      createPayment: {
        query: createPaymentMutation,
        variables: defaultVariables
      }
    }
  );
  return clientMutate(context, {
    mutation: createPaymentGql.query,
    variables: createPaymentGql.variables,
    fetchPolicy: 'no-cache'
  });
};

module.exports = {
  createCustomPayment
};

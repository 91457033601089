const { gql } = require('graphql-tag');

const CartFragment = require('../../fragments/defaultCart').fragment;

module.exports = gql`
  ${CartFragment}

  query getBasicProfile($locale: Locale!, $acceptLanguage: [Locale!], $currency: Currency!) {
    me {
      activeCart {
        ...DefaultCart
      }
    }
  }
`;

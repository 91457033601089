const { gql } = require('graphql-tag');

const DefaultCart = require('../fragments/defaultCart').fragment;
const DefaultCustomer = require('../fragments/defaultCustomer').fragment;

/** Extending the query in order to get user (customer) ID from response */
/** Change in DefaultCustomer fragment */
module.exports = gql`
  ${DefaultCustomer}
  ${DefaultCart}

  mutation customerSignMeIn($draft: CustomerSignMeInDraft!, $locale: Locale!, $acceptLanguage: [Locale!], $currency: Currency!, $storeKey: KeyReferenceInput) {
    user: customerSignMeIn(draft: $draft, storeKey: $storeKey) {
      customer {
        ...DefaultCustomer
      }
      cart {
        ...DefaultCart
      }
    }
  }
`;

import { useVSFContext } from '@vue-storefront/core';
import { IntegrationCollection } from '~/types/integrations/IntegrationCollection';

export const useIntegrations = (): IntegrationCollection => {
  const { $integrations } = useVSFContext();

  return {
    $ct: $integrations.$ct,
    $tagManager: $integrations.$tagManager,
    $bloomreach: $integrations.$bloomreach,
    $cia: $integrations.$cia,
    $vat: $integrations.$vat,
    $graham: $integrations.$graham,
    $myOrders: $integrations.$myOrders
  };
};

const { gql } = require('graphql-tag');
const DefaultCart = require('../fragments/defaultCart').fragment;

module.exports = gql`
  ${DefaultCart}

  mutation deleteCart($id: String!, $version: Long!, $locale: Locale!, $acceptLanguage: [Locale!],
     $currency: Currency!) {
    cart: deleteMyCart(id: $id, version: $version) {
      ...DefaultCart
    }
  }
`;

/**
 * @param {string[]} values
 * @param {string} key
 * @returns {string}
 */

const buildWhereClause = (values, key) => {
  if (!values.length || !key) {
    return '';
  }
  return values.map(value => `${key}="${value}"`).join(' or ');
};

module.exports.buildWhereClause = buildWhereClause;

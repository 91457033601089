// eslint-disable-next-line @typescript-eslint/naming-convention
let AttributeType;
// eslint-disable-next-line @typescript-eslint/naming-convention
(function (AttributeType) {
  AttributeType.STRING = 'StringAttribute';
  AttributeType.DATE = 'DateAttribute';
  AttributeType.DATETIME = 'DateTimeAttribute';
  AttributeType.TIME = 'TimeAttribute';
  AttributeType.NUMBER = 'NumberAttribute';
  AttributeType.ENUM = 'EnumAttribute';
  AttributeType.LOCALIZED_ENUM = 'LocalizedEnumAttribute';
  AttributeType.LOCALIZED_STRING = 'LocalizedStringAttribute';
  AttributeType.MONEY = 'MoneyAttribute';
  AttributeType.BOOLEAN = 'BooleanAttribute';
})(AttributeType || (AttributeType = {}));

const mapFilterToPredicate = (settings, filter) => {
  const { locale, currency } = settings;
  let valuePredicate;
  switch (filter.type) {
    case AttributeType.STRING:
      valuePredicate = `value = "${filter.value}"`;
      break;
    case AttributeType.DATE:
    case AttributeType.DATETIME:
    case AttributeType.TIME:
      valuePredicate = Array.isArray(filter.value) ? `value >= "${filter.value[0]}" and value <= "${filter.value[1]}"` : `value = "${filter.value}"`;
      break;
    case AttributeType.NUMBER:
      valuePredicate = Array.isArray(filter.value) ? `value >= ${filter.value[0]} and value <= ${filter.value[1]}` : `value = ${filter.value}`;
      break;
    case AttributeType.ENUM:
    case AttributeType.LOCALIZED_ENUM:
      valuePredicate = `value(key = "${filter.value}")`;
      break;
    case AttributeType.LOCALIZED_STRING:
      valuePredicate = `value(${locale} = "${filter.value}")`;
      break;
    case AttributeType.MONEY:
      valuePredicate = Array.isArray(filter.value)
        ? `value(centAmount >= ${filter.value[0] * 100} and centAmount <= ${filter.value[1] * 100} and currencyCode = "${currency}")`
        : `value(centAmount = ${filter.value} and currencyCode = "${currency}")`;
      break;
    case AttributeType.BOOLEAN:
      valuePredicate = `value = ${filter.value}`;
      break;
  }
  return `masterData(current(masterVariant(attributes(name = "${filter.name}" and ${valuePredicate}))))`;
};

/**
 * @param {import('@vue-storefront/core').Context} settings
 * @param {ProductsSearchParamsExtended} search
 */
module.exports.buildProductWhere = (settings, search) => {
  const { acceptLanguage } = settings;
  const predicates = [];
  if (search?.customFilters) {
    predicates.push(search.customFilters);
  }
  if (search?.catId) {
    const catIds = (Array.isArray(search.catId) ? search.catId : [search.catId]).join('","');
    predicates.push(`masterData(current(categories(id in ("${catIds}"))))`);
  }
  if (search?.slug) {
    const predicate = acceptLanguage.map(locale => `${locale}="${search.slug}"`).join(' or ');
    predicates.push(`masterData(current(slug(${predicate})))`);
  }
  if (search?.id) {
    predicates.push(`id="${search.id}"`);
  }
  if (search?.filters) {
    const filterPredicates = search.filters.map((f) => mapFilterToPredicate(settings, f)).join(' or ');
    if (filterPredicates) {
      predicates.push(filterPredicates);
    }
  }
  if (search?.key) {
    predicates.push(`key="${search.key}"`);
  }
  if (search?.ids) {
    predicates.push(`id in ("${search.ids.join('","')}")`);
  }
  if (search && typeof search.isOnStock === 'boolean') {
    predicates.push(`masterData(current(masterVariant(availability(isOnStock = ${search.isOnStock}))))`);
  }
  return predicates.join(' and ') || null;
};

import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import { productGetters } from '@vsf-enterprise/commercetools';
import { ProductProcessedAttributes } from '~/types/product/attribute/ProductProcessedAttributes';
import { centAmountToNormalPrice } from '~/helpers/cart/getTotalPricesModule';

export const getProductPriceAfterDiscounts = (product: ProductVariant) => {
  const discountedPrice = centAmountToNormalPrice(product?.price?.discounted?.value?.centAmount) ||
    productGetters.getPrice(product).regular || 0;
  return discountedPrice;
};

export const extractStandardPrice = (attributesLocalized?: ProductProcessedAttributes): number|null => {
  return attributesLocalized?.standardPrice
    ? JSON.parse(attributesLocalized.standardPrice)?.centAmount / 100
    : null;
};
